<template>
    <aside class="modal_sidebar sidebar">
      <h4 class="heading_sm">{{ $t("project_subtitle") }}</h4>
      <ul class="sidebar_list">
        <li class="sidebar_item color_black text_lg">{{ $t("stage_1") }}</li>
        <li class="sidebar_item color_black text_lg">{{ $t("stage_2") }}</li>
        <li class="sidebar_item color_black text_lg">{{ $t("stage_3") }}</li>
        <li class="sidebar_item color_black text_lg">{{ $t("stage_4") }}</li>
        <li class="sidebar_item color_black text_lg">{{ $t("stage_5") }}</li>
        <li class="sidebar_item color_black text_lg">{{ $t("stage_6") }}</li>
        <li class="sidebar_item color_black text_lg">{{ $t("stage_7") }}</li>
        <li class="sidebar_item color_black text_lg">{{ $t("stage_8") }}</li>
        <li class="sidebar_item color_black text_lg">{{ $t("stage_9") }}</li>
        <li class="sidebar_item color_black text_lg">{{ $t("stage_10") }}</li>
        <li class="sidebar_item color_black text_lg">{{ $t("stage_11") }}</li>
        <li class="sidebar_item color_black text_lg">{{ $t("stage_12") }}</li>
      </ul>
    </aside>
  </template>