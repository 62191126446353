<template>
  <header class="header d-flex justify-content-between w-100">
    <img class="logo" src="./assets/images/svg/logo.svg" alt="logo-svg" />
    <img
      class="logo-mobile"
      src="./assets/images/svg/logo.svg"
      alt="logo-svg"
    />

    <div class="lang_buttons">
      <a href="https://app.ilavista.by/" class="color_main heading_ssm lang font_600" v-if="$i18n.locale === 'en'">RU</a>
      <a href="https://app.ilavista.com/" class="color_main heading_ssm lang font_600" v-if="$i18n.locale === 'ru'">EN</a>
    </div>
  </header>

  <section class="main">
    <div class="container">
      <div class="main--container">
        <div class="main_inner-text">
          <h1 v-if="$i18n.locale === 'en' && !isScreenSmall" class="heading_xxl">
            {{ $t("heading") }}
          </h1>

          <h1 v-if="$i18n.locale === 'en' && isScreenSmall" class="heading_xxl">
            {{ $t("heading_sm") }}
          </h1>

          <h1 v-if="$i18n.locale === 'ru'" class="heading_xxl_ru">
            {{ $t("heading") }}
          </h1>

          <p class="main_text color_main heading_ssm">{{ $t("main_text") }}</p>

          <a href="https://calendly.com/valeryan-brunin" target="_blank" class="main-button heading_ssm">
            <span class="label">{{ $t("main_button") }}</span>
            <span class="icon">
              <span></span>
            </span>
            <span class="icon2"> </span>
          </a>
        </div>
        <div class="image-container">
          <picture class="main-img">
            <source srcset="./assets/images/main.webp" type="image/webp" />
            <img
              id="mainImage"
              src="./assets/images/main.png"
              alt="main-image"
            />
          </picture>
        </div>
      </div>
    </div>
  </section>

  <section class="about">
    <div class="container">
      <h2 v-html="$t('about_heading')" class="heading_xxl section"></h2>
      <div class="about_switcher-inner">
        <div
          class="custom-radio-buttons left"
          :class="{ left: state == 'on', right: state == 'off' }"
        >
          <div class="radio--item" @click="switcher = 'on'" id="switcherOn">
            <input v-model="state" type="radio" id="on" value="on" />
            <label for="on" class="label_left">Flutter</label>
          </div>
          <div class="radio--item" @click="switcher = 'off'" id="switcherOff">
            <input v-model="state" type="radio" id="off" value="off" />
            <label for="off" class="label_right">Native</label>
          </div>
        </div>
      </div>

      <Transition mode="out-in">
        <div class="about_content content" v-if="switcher === 'on'">
          <div class="content_inner inner inner_flutter">
            <div class="inner_desc">
              <h4>Flutter -</h4>
              <p class="heading_ssm color_white">{{ $t("flutter_desc") }}</p>
            </div>
            <div class="inner_advantages advantages_list">
              <h5 class="heading_sm">{{ $t("flutter_heading") }}</h5>
              <ul class="advantages_list">
                <li class="text_lg">{{ $t("flutter_item1") }}</li>
                <li class="text_lg">{{ $t("flutter_item2") }}</li>
                <li class="text_lg">{{ $t("flutter_item3") }}</li>
                <li class="text_lg">{{ $t("flutter_item4") }}</li>
                <li class="text_lg">{{ $t("flutter_item5") }}</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="about_content content" v-else>
        <div class="content_inner inner inner_native">
          <div class="inner_advantages advantages_list">
            <h5 class="heading_sm">{{ $t("native_heading") }}</h5>
            <ul class="advantages_list">
              <li class="text_lg">{{ $t("native_item1") }}</li>
              <li class="text_lg">{{ $t("native_item2") }}</li>
              <li class="text_lg">{{ $t("native_item3") }}</li>
              <li class="text_lg">{{ $t("native_item4") }}</li>
            </ul>
          </div>
          <div class="inner_desc">
            <h4>Native</h4>
            <p class="heading_ssm color_white">{{ $t("native_desc") }}</p>
          </div>
        </div>
        </div>
      </Transition>

      
    </div>
  </section>

  <section class="quote quote--first text-animated" id="qoute">
    <div class="container">
      <p class="quote_text heading_xl color_primary">
        <span><img src="./assets/images/svg/quotes.svg" alt="" /></span>
        {{ $t("quote_text") }}
        <span><img src="./assets/images/svg/quotes.svg" alt="" /></span>
      </p>
    </div>
  </section>

  <section class="features">
    <div class="container">
      <h2 class="heading_xxl section">{{ $t("features_title") }}</h2>
    </div>
    <div class="features_list-desktop list">
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item text">
        <h4 class="heading_md color_primary">{{ $t("list_title7") }}</h4>
        <p class="color_black text_lg">{{ $t("list_text7") }}</p>
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item text">
        <h4 class="heading_md color_primary">{{ $t("list_title8") }}</h4>
        <p class="color_black text_lg">{{ $t("list_text8") }}</p>
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item text">
        <h4 class="heading_md color_primary">{{ $t("list_title9") }}</h4>
        <p class="color_black text_lg">{{ $t("list_text9") }}</p>
      </div>

      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
      <div class="item text">
        <h4 class="heading_md color_primary">{{ $t("list_title10") }}</h4>
        <p class="color_black text_lg">{{ $t("list_text10") }}</p>
      </div>
      <div class="item">
        <img src="./assets/images/svg/cube.svg" alt="cube-img" />
      </div>
    </div>
  </section>

  <section class="projects">
    <div class="container">
      <h2 class="heading_xxl section">
        {{ $t("projects_title") }}
      </h2>
      <div class="projects_inner">
        <div
          v-for="(project, index) in projects"
          :key="index"
          class="projects_project project"
        >
          <div class="project_box">
            <h3 class="heading_xl color_primary">{{ project.title }}</h3>
            <span class="text_sm color_secondary">{{ project.subtitle }}</span>
            <p class="heading_ssm color_black">{{ project.description }}</p>
            <button class="button_more" @click="openModal(index)">
              {{ $t("btn_more") }}
            </button>
          </div>

          <div class="project_img">
            <img :src="project.image" :alt="project.title + '-img'" />
          </div>

          <Transition name="slide-fade">
            <AppModalVue
              :class="{ active: modalVisible }"
              v-if="modalVisible && selectedProject === index"
              :selectedProject="selectedProject"
              @close-modal="closeModal"
            />
          </Transition>
        </div>
      </div>
    </div>
  </section>

  <section class="process">
    <div class="container">
      <span class="heading_md mb-3 section">{{ $t("process_subtitle") }}</span>
      <h2 class="heading_xxl section">{{ $t("process_title") }}</h2>

      <div class="process_inner">
        <div class="process_box">
          <div class="box_top">
            <img src="./assets/images/svg/smallCube.svg" alt="cube-img" />
            <img
              class="arrow-img"
              src="./assets/images/svg/arrow.svg"
              alt="arrow-img"
            />
            <img
              class="arrow-img--mob"
              src="./assets/images/svg/arrowMob.svg"
              alt="arrow-img"
            />

          </div>
          <h3>{{ $t("process_step_1_title") }}</h3>
          <p>{{ $t("process_step_1") }}</p>
        </div>
        <div class="process_box">
          <div class="box_top">
            <img src="./assets/images/svg/smallCube.svg" alt="cube-img" />
            <img
              class="arrow-img"
              src="./assets/images/svg/arrow.svg"
              alt="arrow-img"
            />
            <img
              class="arrow-img--mob"
              src="./assets/images/svg/arrowMob.svg"
              alt="arrow-img"
            />
          </div>
          <h3>{{ $t("process_step_2_title") }}</h3>
          <p>{{ $t("process_step_2") }}</p>
        </div>
        <div class="process_box">
          <div class="box_top">
            <img src="./assets/images/svg/smallCube.svg" alt="cube-img" />
            <img
              class="arrow-img"
              src="./assets/images/svg/arrow.svg"
              alt="arrow-img"
            />
            <img
              class="arrow-img--mob"
              src="./assets/images/svg/arrowMob.svg"
              alt="arrow-img"
            />
          </div>
          <h3>{{ $t("process_step_3_title") }}</h3>
          <p>{{ $t("process_step_3") }}</p>
        </div>
        <div class="process_box">
          <div class="box_top">
            <img src="./assets/images/svg/smallCube.svg" alt="cube-img" />
            <img
              class="arrow-img"
              src="./assets/images/svg/arrow.svg"
              alt="arrow-img"
            />
            <img
              class="arrow-img--mob"
              src="./assets/images/svg/arrowMob.svg"
              alt="arrow-img"
            />
          </div>
          <h3>{{ $t("process_step_4_title") }}</h3>
          <p>{{ $t("process_step_4") }}</p>
        </div>
        <div class="process_box">
          <div class="box_top">
            <img src="./assets/images/svg/smallCube.svg" alt="cube-img" />
          </div>
          <h3>{{ $t("process_step_5_title") }}</h3>
          <p>{{ $t("process_step_5") }}</p>
        </div>
      </div>
    </div>
  </section>

  <section class="process_statistic">
    <div class="container">
      <div class="statistic_container">
        <div class="statistic">
          <div class="heading_ssm">{{ $t("statistic_title1") }}</div>
          <div class="heading_ssm center" v-html="$t('statistic_title2')"></div>
          <div class="heading_ssm center">{{ $t("statistic_title3") }}</div>
          <div class="heading_ssm center">{{ $t("statistic_title4") }}</div>
          <div class="heading_ssm center">{{ $t("statistic_title5") }}</div>

          <span class="statistic_item w-70">{{ $t("statistic_item1") }}</span>
          <span class="statistic_item statistic_item--second w-100">{{
            $t("statistic_item2")
          }}</span>
          <span class="statistic_item statistic_item--third w-100">{{
            $t("statistic_item3")
          }}</span>
          <span class="statistic_item statistic_item--fourth w-100">{{
            $t("statistic_item4")
          }}</span>
          <span class="statistic_item statistic_item--fifth">{{
            $t("statistic_item5")
          }}</span>
          <span class="statistic_item statistic_item--six">{{
            $t("statistic_item6")
          }}</span>
          <span class="statistic_item statistic_item--seven">{{
            $t("statistic_item7")
          }}</span>
          <span class="statistic_item statistic_item--eight">{{
            $t("statistic_item8")
          }}</span>
          <span class="statistic_item statistic_item--nine">{{
            $t("statistic_item9")
          }}</span>
          <span class="statistic_item statistic_item--ten">{{
            $t("statistic_item10")
          }}</span>
          <span class="statistic_item statistic_item--eleven">{{
            $t("statistic_item11")
          }}</span>
          <span class="statistic_item statistic_item--twelve">{{
            $t("statistic_item12")
          }}</span>
        </div>

        <div class="statistic_layout">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </section>

  <section class="quote_second text-animated quote">
    <div class="container">
      <p class="quote_text heading_xl color_primary">
        <span><img src="./assets/images/svg/quotes.svg" alt="" /></span>
        {{ $t("quote_text_second") }}
        <span><img src="./assets/images/svg/quotes.svg" alt="" /></span>
      </p>
    </div>
  </section>

  <div class="footer_quote text-animated">
    <div class="container">
      <h2>{{ $t("footer_quote") }}</h2>
    </div>
  </div>

  <footer class="footer">
    <div class="container">
      <div class="footer_inner">
        <h3 class="heading_ssm">{{ $t("footer_title") }}</h3>
        <a href="https://calendly.com/valeryan-brunin" target="_blank" class="main-button heading_ssm main-button--dark">
          <span class="label">{{ $t("main_button") }}</span>
          <span class="icon">
            <span></span>
          </span>
          <span class="icon2"> </span>
        </a>
      </div>
    </div>
  </footer>

  <a class="book_button text_lg" target="_blank" href="https://calendly.com/valeryan-brunin">{{ $t("book_btn") }}</a>
</template>

<script>
import AppModalVue from "./components/AppModal.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "App",
  components: {
    AppModalVue,
  },
  data() {
    return {
      switcher: "on",
      modalVisible: false,
      selectedProject: null,
      state: "",
      screenWidth: window.innerWidth,
      isScreenSmall: false,
    };
  },
  computed: {
    projects() {
      return [
        {
          title: "Hi, neighbour!",
          subtitle: this.$t("project_subtitle1"),
          description: this.$t("project_desc1"),
          image: "/projects/sosed.jpg",
        },
        {
          title: "GTE",
          subtitle: this.$t("project_subtitle2"),
          description: this.$t("project_desc2"),
          image: "/projects/gte.jpg",
        },
        {
          title: "FINDGID",
          subtitle: this.$t("project_subtitle3"),
          description: this.$t("project_desc3"),
          image: "/projects/findgid.jpg",
        },
      ];
    },
    listItems() {
      return [
        { titleKey: this.$t("list_title1"), textKey: this.$t("list_text1") },
        { titleKey: this.$t("list_title2"), textKey: this.$t("list_text2") },
        { titleKey: this.$t("list_title3"), textKey: this.$t("list_text3") },
        { titleKey: this.$t("list_title4"), textKey: this.$t("list_text4") },
        { titleKey: this.$t("list_title5"), textKey: this.$t("list_text5") },
        { titleKey: this.$t("list_title6"), textKey: this.$t("list_text6") },
      ];
    },
  },
  methods: {
    openModal(index) {
      this.selectedProject = index;
      this.modalVisible = true;
      document.body.classList.add("no-scroll");
    },
    closeModal() {
      this.modalVisible = false;
      this.selectedProject = null;
      document.body.classList.remove("no-scroll");
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
      this.isScreenSmall = this.screenWidth < 420;
    },
  },
  mounted() {
    this.updateScreenWidth();
    window.addEventListener('resize', this.updateScreenWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenWidth); 
  },
};
</script>

<style lang="sass">
@import "@/assets/styles/main.sass"
</style>
