import {createI18n} from 'vue-i18n'


function getCurrentDomain() {
    // Получаем текущий URL страницы
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    if (urlParts.length >= 3) {
      return urlParts[2];
    }
    return "";
  }


let locale = "en";

const currentDomain = getCurrentDomain();
if (currentDomain.includes(".by")) {
    locale = "ru";
} else if (currentDomain.includes(".com")) {
    locale = "en";
}

document.addEventListener('DOMContentLoaded', function() {
    const body = document.body;
    if (locale === 'ru') {
      body.classList.add('app-ru');
    } else {
      body.classList.add('app-en');
    }
  });

const i18n = createI18n({
    locale: locale,
    messages: {
        en: {
            heading: "Mobile Apps Development",
            heading_sm: "Mobile Development",
            main_text: "From ideation to full-scale design and launch — we’ll help you choose an effective path in digital technologies and support its development",
            main_button: "Book a meet",
            about_heading: `Development <br/> Capabilities`,
            flutter_desc: "Flutter is a popular cross-platform framework by Google that enables you to develop high-performance applications simultaneously for iOS and Android",
            flutter_heading: "When is the best time to choose Flutter?",
            flutter_item1: "The app must work on iOS and Android",
            flutter_item2: "Limited resources and timing",
            flutter_item3: "Complex design, visual effects or animation",
            flutter_item4: "Efficient resource management",
            flutter_item5: "Experimenting and proof of concept",
            native_desc: "Despite the convenience of Flutter, for certain project requirements, it is advisable to prioritize native app development tailored to a specific platform - either Android or iOS", 
            native_heading: "When is it better to choose native development?",
            native_item1: "Optimization for a specific platform (for instance, iOS only)",
            native_item2: "Full access to the device functionality (camera, geolocation, contacts, etc.) is required",
            native_item3: "Unique design",
            native_item4: "Specific security requirements",

          
            quote_text: "Regardless of your goals and the approach to app development, entrusting tasks to a team of professionals will be the best solution",
            features_title: "Why to choose us?",
            list_title7: "Transparency",
            list_title8: "Speed",
            list_title9: "Flexibility",
            list_title10: "Technologies",
            list_text7: "We operate with flexible methodologies. Development is divided into stages to break down tasks, better plan workloads, and budgets",
            list_text8: "When your idea is current, becoming the first to realize it is crucial. We won't keep you waiting and will swiftly launch a reliable product that ensures your superiority",
            list_text9: "Depending on your project, we'll assemble a team based on the required technologies, workload, and budget, maintaining efficiency and adhering to our development principles",
            list_text10: "We work with Flutter, Kotlin, and Swift technologies to tailor the optimal development approach for each business",


            projects_title: "Recent successful cases",


            project_subtitle1: "Social network",
            project_subtitle2: "Logistic",
            project_subtitle3: "Tourism",

            project_desc1: "Neighborhood Social Network",
            project_desc2: "Application for Logistics Company Drivers",
            project_desc3: "Application for Finding Tours and Excursions Worldwide",


            btn_more: "Read more",
            btn_back: "Base to landing",
            project_subtitle: "Stages of development",

            stage_1: "Data Collection",
            stage_2: "Road map",
            stage_3: "Business analisys",
            stage_4: "UX Research",
            stage_5: "UX Prototype",
            stage_6: "Technical documentation",
            stage_7: "UI Design",
            stage_8: "Product Testing",
            stage_9: "System architecture",
            stage_10: "Programming",
            stage_11: "Release",
            stage_12: "Support ",

            request_title: "Request",
            result_title: "Result",
            nowadays_title: "Nowadays",
            request_text: "A major investment holding company had an idea to create a social network for neighbors with personal profiles, chats, communities, and content creation tools. Before the project came to us, two IT companies declined the development due to the project's complexity and tight deadlines. However, we took on the challenge and got to work",

            result_text: "The challenge during the development process was the tight schedule. The client requested an application that wouldn't compromise on user-friendliness compared to modern social networks, but we didn't have much time for extensive testing. Our designers and developers demonstrated flexibility and the ability to make quick decisions, allowing us to meet the deadlines. Within just 4 months, we presented the client with the first version of the product. </br> The development was carried out using Flutter, as the client wanted availability on both iOS and Android simultaneously. We included features like user profiles, news feeds, friends, groups and communities, event schedules and reservations, individual and group chats allowing text and voice messages",

            nowadays_text: `The application has been successfully launched on iOS and Android. The client's team is currently focused on attracting new users, while we are working on developing an administration panel within the application and collecting product statistics. The client has ambitious plans ahead, and we will continue to assist them in their technical implementation. <br/> The app can be downloaded from <a target="_blank" href="https://play.google.com/store/games?device=windows">Google Play</a> and <a target="blank" href="https://www.apple.com/pl/app-store/">the App Store</a>`,          

            request_text2: "Logistics company GTE approached us for the development of a convenient mobile application for their drivers, allowing them to plan their work and routes",
            result_text2: `The client required the application to work simultaneously on both iOS and Android, leading us to decide to begin development using Flutter. </br> Catering to the company employees' needs, we crafted an intuitively comprehensible mobile application equipped with essential features for drivers: route viewing, schedule and vacation planning, access to order information and trip details, and an interactive route map displaying rest areas, refueling points, and other necessary data`,
            nowadays_text2: `The application is currently in the process of being implemented within the company. Real-time transportation information aids drivers in making more optimal decisions, reducing travel time, and enhancing delivery efficiency. Drivers provide feedback to enable us to make the application even more personalized and user-friendly. The app can be downloaded from <a target="_blank" href="https://play.google.com/store/apps/details?id=de.gtegmbh.driverapp">Google Play</a> and <a target="blank" href="https://apps.apple.com/us/app/gte-gmbh-driver-app/id6448729251">the App Store</a>`,
            request_text3: "Our client's company assists tourists in finding excursions led by local guides. Thus, FindGid approached us for the development of a mobile application that would enable travelers and guides to easily connect",
            result_text3: "Prior to development, we conducted business analysis and UX research. Based on our findings, we crafted a cross-platform application. Guides gained the ability to create their own excursions, define routes, and set pricing. Meanwhile, travelers could book and pay for tours through the platform. </br> After booking, a chat is initiated between the guide and traveler for discussing excursion details. Travelers also gained the feature to navigate to the meetup point and track the route in real-time. For guides, we integrated statistics, attendance analytics, and booking confirmation capabilities into their profile. </br> The application was launched 6 months after development initiation",
            nowadays_text3:`The platform is currently attracting users and guides. Meanwhile, we continue to incorporate product analytics tools into the platform, assisting businesses in making decisions regarding growth and development strategies. Simultaneously, we are developing a separate application for guides, facilitating easier excursion and route planning. The app can be downloaded from <a target="_blank" href="https://play.google.com/store/apps/details?id=ru.findgid.findgid">Google Play</a> and <a target="blank" href="https://apps.apple.com/pl/app/findgid/id6446320209">the App Store</a>`,

            process_subtitle: "Preparation",
            process_title: "Project process",

            process_step_1_title: "Start",
            process_step_2_title: "BA/UX  Design",
            process_step_3_title: "UI Design",
            process_step_4_title: "Development",
            process_step_5_title: "Release/Support",


            process_step_1: "We'll analyze your task, gather necessary data, create a project road map, and select a development team",
            process_step_2: "We'll conduct market research and study the target audience to identify a list of user needs and product implementation directions, considering business requirements",
            process_step_3: "We'll create an intuitively understandable and appealing design for the user experience and a seamless interface",
            process_step_4: "Well write code for the application to make the design functional and integrate the project with the server",
            process_step_5: "We'll launch the project, continue its development, update it, and scale as needed",


            statistic_title1: "Start",
            statistic_title2: "Business analisys / </br> UX Design",
            statistic_title3: "UI Design",
            statistic_title4: "Development",
            statistic_title5: "Release an support",
            statistic_item1: "Data Gathering",
            statistic_item2: "Project Roadmap",
            statistic_item3: "Business Analysis",
            statistic_item4: "UX Research",
            statistic_item5: "UX Prototypes",
            statistic_item6: "Technical Documentation",
            statistic_item7: "UI Design",
            statistic_item8: "Product Testing",
            statistic_item9: "Project Architecture",
            statistic_item10: "Programming",
            statistic_item11: "Launch",
            statistic_item12: "Support ",
           
            quote_text_second: "After the development you’ll receive a product ready for download and use. Trust us to determine the optimal path of digital renovation for your business",

            footer_title: "Ready to increase the efficiency of your business?",
            book_btn: "Book a free expert session",
            footer_quote: "Ready to increase the efficiency of your business?"

        },
        ru: {
            heading: "Разработка мобильных приложений",
            main_text: "От идеи до полномасштабного проектирования и запуска— поможем вам выбрать эффективный путь в цифровых технологиях и поддержим его развитие",
            main_button: "Обсудить проект",
            about_heading: "Возможности <br/> разработки",
            flutter_desc: "это популярный кросс-платформенный фреймворк от Google, где вы можете создавать приложения с высокой производительностью одновременно для iOS и Android",
            flutter_heading: "Когда лучше выбрать Flutter?",
            flutter_item1: "Эффективное управление ресурсами",
            flutter_item2: "Приложение должно работать на iOS и Android",
            flutter_item3: "Гибкий дизайн с простыми визуальными эффектами ",
            flutter_item4: "Стандартные требования безопасности",
            flutter_item5: "Проведение экспериментов и проверка концепции",
            native_desc: "Несмотря на удобство Flutter, при определенных требованиях проекта стоит отдать предпочтение нативной разработке приложений, ориетированной на конкретную платформу - Android или iOS", 
            native_heading: "Когда лучше выбрать нативную разработку?",
            native_item1: "Оптимизация для конкретной платформы (например, только IOS) ",
            native_item2: "Необходим полный доступ к функциональности устройства (камере, геолокации, контактам и т.д.) ",
            native_item3: "Уникальный дизайн",
            native_item4: "Специфические требования безопасности",

            
            quote_text: "Независимо от ваших целей и способа разработки приложения, делегирование задач на команду профессионалов станет лучшим решением",

            features_title: "Почему ILAVISTA?",
            list_title7: "Прозрачность",
            list_title8: "Скорость",
            list_title9: "Гибкость",
            list_title10: "Технологии",
            list_text7: "Мы работаем с гибкими методологиями. Разработка разделена на этапы, чтобы декомпозировать задачи, лучше планировать нагрузку и бюджет",
            list_text8: "Когда ваша идея актуальна, важно стать в ее реализации первым. Мы не заставим вас ждать и быстро выпустим надежный продукт, с которым вы можете быть уверены в своем превосходстве",
            list_text9: "В зависимости от вашего проекта мы подберем команду под необходимые технологии, объем работ и бюджет, сохраняя эффективность и следуя нашим принципам разработки",
            list_text10: "Мы работаем с технологиями Flutter, Kotlin и Swift, чтобы подбирать оптимальный вариант разработки для каждого бизнеса",
            projects_title: "Актуальные проекты",


            project_subtitle1: "Социальная сеть",
            project_subtitle2: "Логистика",
            project_subtitle3: "Туризм",

            project_desc1: "Социальная сеть для соседей",
            project_desc2: "Приложение для водителей логистической компании",
            project_desc3: "Приложение для поиска экскурсий и туров по всему миру",


            btn_more: "Подробнее",
            btn_back: "Назад",
            project_subtitle: "Этапы разработки",

            stage_1: "Сбор данных",
            stage_2: "Карта проекта",
            stage_3: "Бизнес анализ",
            stage_4: "UX Исследование",
            stage_5: "UX прототипы",
            stage_6: "Техническая документация",
            stage_7: "UI Дизайн ",
            stage_8: "Тестирование продукта",
            stage_9: "Архитектура проекта",
            stage_10: "Программирование",
            stage_11: "Запуск",
            stage_12: "Поддержка",


            request_title: "Запрос",
            result_title: "Результат",
            nowadays_title: "Сегодня",
            request_text: "У крупного инвестиционного холдинга появилась идея создать социальную сеть для соседей с личными профилями, чатами, сообществами и инструментами для создания контента. До того, как проект попал в наши руки, две IT-компании отказались от разработки из-за сложности проекта и сжатых сроков. Но мы приняли вызов и взялись за работу",
            result_text: `Сложностью в процессе разработки стали сжатые сроки, ведь по запросу клиента, приложение должно было не уступать по удобству современным социальным сетям, однако времени на долгие тесты у нас не было. Наши дизайнеры и разработчики продемонстрировали свою гибкость и умение быстро принимать решения, поэтому мы уложись в сроки и уже через 4 месяца показали заказчику первую версию продукта. </br> Разработка велась на Flutter, потому что для клиента была важна доступность на IOS и Android одновременно. Мы включили в приложение личный кабинет пользователя, ленту новостей, друзей, группы и сообщества, афишу и запись на мероприятия, индивидуальные и групповые чаты, в которых можно отправлять текстовые и голосовые сообщения`,
            nowadays_text: `Приложение успешно запущено на IOS и Android. Сейчас команда заказчика занимается привлечением новых пользователей, а мы - разработкой панели администрирования внутри приложения и сбором продуктовой статистики. Впереди у заказчика много планов, и мы будем помогать ему с их технической реализацией. Приложение можно скачать на  <a target="_blank" href="https://play.google.com/store/games?device=windows">Google Play</a> и <a target="blank" href="https://www.apple.com/pl/app-store/">the App Store</a>`,
            request_text2: "Логистическая компания GTE обратилась к нам за разработкой удобного мобильного приложения для водителей, где они могли бы планировать свою работу и маршруты",
            result_text2: `Клиенту было нужно, чтобы приложение работало одновременно на IOS и Android, поэтому мы приняли решение начать разработку на Flutter. </br> Ориентируясь на требования сотрудников компании, мы разработали интуитивно понятное мобильное приложение с необходимыми для водителей функциями: просмотром маршрутов, составлением графика работы и отпусков, получением информации о заказах и деталях поездки, а также интерактивной картой маршрута с отображением зон отдыха, заправок и других необходимых данных`,
            nowadays_text2: `Приложение находится на стадии внедрения в компанию. Текущая информация о потоке транспорта помогает водителям принимать более оптимальные решения, сокращая время в пути и повышая эффективность доставок. Водители оставляют обратную связь, чтобы мы могли сделать приложение еще более персонализированным и удобным в использовании. Приложение можно скачать на  <a target="_blank" href="https://play.google.com/store/apps/details?id=de.gtegmbh.driverapp">Google Play</a> и <a target="blank" href="https://apps.apple.com/us/app/gte-gmbh-driver-app/id6448729251">the App Store</a> `,
            request_text3: "Компания нашего клиента помогает туристам с поиском экскурсий от местных гидов, поэтому FindGid обратились к нам за разработкой мобильного приложения для того, чтобы путешественники и экскурсоводы могли с лёгкостью найти друг друга",
            result_text3: `Перед разработкой мы провели бизнес-анализ и UX-research. Основываясь на исследованиях, мы создали кроссплатформенное приложение, где у гидов появилась возможность создавать свои экскурсии, сформировать маршрут и определить его стоимость, а у путешественников - забронировать экскурсию и оплатить ее на платформе. </br> После такого бронирования между гидом и путешественником создается чат, в котором они могут обсудить детали экскурсии. Для путешественника, мы добавили возможность проложить маршрут до точки сбора группы и отслеживание маршрута в режиме реального времени. В личный кабинет гида мы добавили статистику и аналитику посещаемости экскурсий, возможность подтверждения брониравания. </br> Приложение было запущено через 6 месяцев после начала разработки`,
            nowadays_text3:`Сейчас платформа привлекает пользователей и гидов. Мы же продолжаем внедрять в платформу инструменты продуктовой аналитики, на основании которых бизнес принимает решения о дальнейших путях роста и развития. Параллельно мы разрабатываем отдельное приложение для гидов для более удобного планирования экскурсий и маршрутов. Приложение можно скачать на  <a target="_blank" href="https://play.google.com/store/apps/details?id=ru.findgid.findgid">Google Play</a> и <a target="blank" href="https://apps.apple.com/pl/app/findgid/id6446320209">the App Store</a> `,

            process_subtitle: "Подготовка",
            process_title: "Этапы разработки",


            process_step_1_title: "Старт",
            process_step_2_title: "БА/UX Дизайн ",
            process_step_3_title: "UI Дизайн",
            process_step_4_title: "Разработка",
            process_step_5_title: "Релиз/Поддержка",
           
            process_step_1: "Изучим вашу задачу, соберем необходимые данные, создадим road map проекта и выберем команду для разработки",
            process_step_2: "Исследуем рынок и целевую аудиторию, чтобы выявить список потребностей пользователей и направления реализации продукта с учетом бизнес-требований",
            process_step_3: "Создадим интуитивно понятный и привлекательный дизайн пользовательского опыта и бесшовного интерфейса",
            process_step_4: "Напишем код для приложения, чтобы сделать дизайн функциональным и интегрировать проект с сервером",
            process_step_5: "Запустим проект, продолжим его развивать, обновлять и масштабировать",


            statistic_title1: "Старт",
            statistic_title2: "Бизнес анализ/ </br> UX Дизайн ",
            statistic_title3: "UI Дизайн",
            statistic_title4: "Разработка",
            statistic_title5: "Запуск и поддержка",
            statistic_item5: "UX прототипы",
            statistic_item6: "Техническая документация",
            statistic_item7: "UI Дизайн",
            statistic_item8: "Тестирование продукта",
            statistic_item9: "Архитектура проекта",
            statistic_item10: "Программирование",
            statistic_item11: "Запуск",
            statistic_item12: "Поддержка",
            

            statistic_item1: "Сбор данных",
            statistic_item2: "Карта проекта",
            statistic_item3: "Бизнес анализ",
            statistic_item4: "UX Исследование",
         
            quote_text_second: "В результате разработки вы получите готовый для загрузки и использования продукт. Доверьтесь нам, чтобы определить оптимальный путь цифровой модернизации для вашего бизнеса",
            footer_title: "Готовы повысить эффективность своего бизнеса?",
            book_btn: "Обсудить проект",
            footer_quote: "Готовы повысить эффективность своего бизнеса?"
        }
    }
})

export default i18n;