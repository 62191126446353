import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import i18n from "./locales/i18n";
import gsap from "gsap";

import { ScrollTrigger, MotionPathPlugin, TextPlugin } from "gsap/all";

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, TextPlugin);

createApp(App).use(gsap).use(i18n).mount("#app");




//animations

//анимация для самого первого блока
const tl = gsap.timeline();
tl.fromTo(".logo", { opacity: 0, scale: 0.6, y: 1 }, { y: 0, scale: 1, opacity: 1, duration: 1,  ease: "linear" });
tl.fromTo(
  ".main_inner-text",
  { opacity: 0, scale: 0.6, y: 1},
  { y: 0, scale: 1, opacity: 1, duration: 1, ease: "linear" },
  0
);
tl.fromTo(
  ".image-container",
  { opacity: 0 },
  { opacity: 1, ease: "slow", duration: 2 },
  0
);
tl.duration(1.5);

// Анимация для features
const tml = gsap.timeline();
document.querySelectorAll(".item.text").forEach(function (box) {
  tml.from(box, {
    duration: 0.5,
    opacity: 0,
    y: 20,
    scale: 0.4,
    ease: "back.out",
  });
});

ScrollTrigger.create({
  animation: tml,
  trigger: ".features",
  start: "top center",
  endTrigger: ".features",
  end: "bottom 65%",
});

ScrollTrigger.matchMedia({
  "(min-width: 320px)": function () {
    const sectionElements = document.querySelectorAll(".section");

    sectionElements.forEach((sectionBox) => {
      let text = gsap.timeline({
        scrollTrigger: {
          trigger: sectionBox,
          duration: 1,
        },
      });

      text.from(sectionBox, { y: 20, opacity: 0, scale: 0.6,  duration: 1 }).to(sectionBox, { duration: 1, opacity: 1, scale: 1, y: 0 });
    });
  },
});

ScrollTrigger.matchMedia({
  "(min-width: 320px)": function () {
    const quoteTextElements = document.querySelectorAll(".text-animated");

    quoteTextElements.forEach((quoteText) => {
      let text = gsap.timeline({
        scrollTrigger: {
          trigger: quoteText,
          duration: 1,
        },
      });

      text.from(quoteText, { scale: 0.8, opacity: 0, duration: 1 }).to(quoteText, { duration: 1, opacity: 1, scale: 1 });
    });
  },
});

const processBoxes = document.querySelectorAll(".process_box");
const processTimeline = gsap.timeline();
processBoxes.forEach((box, index) => {
  processTimeline.from(box, {
    opacity: 0,
    y: 20,
    duration:  index * 0.3,
    ease: "power2.inOut",
  });
});

ScrollTrigger.create({
  trigger: ".process", 
  start: "top center", 
  end: "bottom center", 
  animation: processTimeline,
});


// для текстовых блоков в about
const switcherOnButton = document.getElementById('switcherOn');
const switcherOnButtonWidth = switcherOnButton.offsetWidth;
document.documentElement.style.setProperty('--left-value', `${switcherOnButtonWidth}px`);
